
export const environment = {
   production: true,
   mfgloc: 'test',
   factory: 'CM01',
   api: {
        url: 'https://api.sandbox.kjubsolutions.com/ltg/api/v1/ltanalytics/'
   },
   timers: []
};
